import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import "./UserChildrens.css";
import { fetchSchools } from "../../utils/fetchSchools";
import { Stepper, Step, StepLabel, StepContent, StepConnector } from "@mui/material";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import GeneralTermsAndConditions from "../PersonalTerms/GeneralTermsAndConditions";
import PersonalDataAgreement from "../PersonalTerms/PersonalDataAgreement";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
} from "@mui/material";
import Cookie from "js-cookie";

interface Child {
  courseName: string;
  schoolName: string;
  courseLanguage: string;
  _id: string;
  name: string;
  schoolId: string;
  courseId: string;
  userId: string;
  className: string;
  isAttending: boolean;
  firstPayment: boolean;
  nextPayment: number;
  createdAt: string;
  paymentMethod: string;
  __v: number;
}

interface UserChildrensProps {
  userId: string;
  userData: any;
  updateUserData: () => void;
}

interface Course {
  _id: string;
  attendIds: string[];
  capacity: number;
  endDate: Date;
  language: string;
  lectorId: string;
  lectorName: string;
  monthPayment: number;
  schoolId: string;
  schoolName: string;
  startDate: Date;
  time: string;
  childrens: string[];
}

interface newChildProps {
  name: string;
  className: string;
  userId?: string;
  schoolId?: string;
  courseId?: string;
}

interface School {
  _id: string; // Use _id as the unique identifier
  name: string;
  location: string;
  town: string;
  address: string; // Add address property
  imgURL: string; // Add imgURL property
  // Add other properties as needed
}

const UserChildrens = ({ userId, userData, updateUserData }: UserChildrensProps) => {
  const [childrens, setChildrens] = useState<Child[]>([]);
  const navigate = useNavigate();
  const [schools, setSchools] = useState<School[]>([]);
  const [selectedSchoolId, setSelectedSchoolId] = useState<string>("");
  const [selectedTown, setSelectedTown] = useState<string>("");
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [selectedCourseId, setSelectedCourseId] = useState<string>("");
  const [selectedCourse, setSelectedCourse] = useState<Course>({} as Course); // TODO ADD selectedCourse
  const { token } = useAuth();

  const [newChildren, setNewChildren] = useState<newChildProps>({
    name: "",
    className: "",
    userId: "",
    schoolId: "",
    courseId: "",
  });
  const [courses, setCourses] = useState<Course[]>([]);
  const [freeCoursePlaces, setFreeCoursePlaces] = useState<number>(0);
  const stepNames = ["Údaje o dieťati", "Mesto & škola", "Jazyk & kurz", "Druh platby", "VOP"];
  const [activeStep, setActiveStep] = useState(0);
  // const Connector = () => <StepConnector />;
  const [completeStepper, setCompleteStepper] = useState<boolean>(false);
  const [stepperMessage, setStepperMessage] = useState<string>("");
  const [isChecked, setIsChecked] = useState(false); // Personal terms agreement
  const [isVOPChecked, setIsVOPChecked] = useState(false); // Personal terms agreement
  const [userSignedPersonalData, setUserSignedPersonalData] = useState(Boolean); // Personal terms agreement
  const [userSignedVOP, setUserSignedVOP] = useState(Boolean); // Personal terms agreement
  const [selectedPayment, setSelectedPayment] = useState<string>("");
  const hidden = true;

  // const [nameAndClassDone, setNameAndClassDone] = useState<boolean>(false);

  // ActiveStepContent

  const languages = [
    {
      _id: "1",
      name: "Python",
    },
    {
      _id: "2",
      name: "Scratch",
    },
    {
      _id: "3",
      name: "Roblox",
    },
  ];

  const towns = [
    {
      _id: "0",
      name: "Banska Bystrica",
    },
    {
      _id: "1",
      name: "Bratislava",
    },
    {
      _id: "2",
      name: "Kosice",
    },
    {
      _id: "3",
      name: "Nitra",
    },
    {
      _id: "4",
      name: "Hlohovec",
    },
    {
      id: "5",
      name: "Piestany",
    },
  ];

  useEffect(() => {
    setUserSignedPersonalData(userData.signedVOP);
  }, [userData.signedVOP]);

  useEffect(() => {
    setUserSignedVOP(userData.signedPersonalData);
  }, [userData.signedPersonalData]);

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    const fetchChildrenData = async () => {
      if (userId === "" || userId === undefined) {
        return;
      }
      try {
        const response = await fetch(url + "children/user/" + userId, {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId, // custom header for user ID
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setChildrens(data.childrens);
      } catch (error) {
        console.error("Error fetching children data:", error);
      }
    };

    fetchChildrenData();
  }, [token, url, userId]);

  useEffect(() => {
    // Fetch schools data using the fetchSchools function
    if (selectedTown !== "" && selectedTown !== undefined) {
      fetchSchools(selectedTown, false)
        .then((data) => setSchools(data))
        .catch((error) => console.log(error));
    }
  }, [selectedTown, url]);

  const handleChildrenDetails = (childId: string) => {
    navigate("/children/user/" + childId + "/details");
  };

  const handleChildrenAttendanceDetails = (childId: string) => {
    navigate("/attend");
  };

  useEffect(() => {
    if (selectedSchoolId && selectedLanguage) {
      const fetchChildrenData = async () => {
        if (userId === "" || userId === undefined) {
          return;
        }
        try {
          const response = await fetch(
            url + "course/getCoursesBySchoolIdAndLanguage/" + selectedSchoolId + "/" + selectedLanguage,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
              },
            }
          );
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          const data = await response.json();
          // TODO - set childrens to the data from the response
          setCourses(data.coursesInSchool);
        } catch (error) {
          console.error("Error fetching children data:", error);
        }
      };
      fetchChildrenData();
    }
  }, [url, userId, selectedSchoolId, selectedLanguage, token]);

  const setNumbertoMonth = (number: number) => {
    switch (number) {
      case 1:
        return "January";
      case 2:
        return "Febuary";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "August";
      case 9:
        return "Septemper";
      case 10:
        return "October";
      case 11:
        return "November";
      case 12:
        return "December";
      default:
        return "";
    }
  };

  const createChildren = async () => {
    const body = {
      name: newChildren.name,
      className: newChildren.className,
      userId: userId,
      schoolId: selectedSchoolId,
      courseId: selectedCourseId,
      paymentMethod: selectedPayment,
    };

    try {
      const response = await fetch(url + "children/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      setChildrens((prev) => [...prev, data]);
      updateUserData();
      // empty the newChildren object
      newChildren.name = "";
      newChildren.className = "";
      setSelectedSchoolId("");
      setSelectedCourseId("");
      setSelectedCourse({} as Course);
      setSelectedLanguage("");
      setSelectedTown("");
    } catch (error) {
      console.error("Error fetching children data:", error);
    }
  };

  const handleSelectTown = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTown(e.target.value);
  };

  // Handle selecting school
  const handleSchoolSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSchoolId(e.target.value);
  };

  const handleLanguageSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLanguage(e.target.value);
  };

  const handleCourseSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "") {
      setSelectedCourse({} as Course);
      setSelectedCourseId("");
      return;
    }
    setSelectedCourseId(e.target.value);
    // by e.target.value we get the id of the selected course
    const selectedCourse = courses.find((course) => course._id === e.target.value);
    setSelectedCourse(selectedCourse as Course);
    if (selectedCourse) {
      let freePlaces = selectedCourse.capacity - selectedCourse.childrens.length;
      setFreeCoursePlaces(freePlaces);
    }
  };

  const checkStepperValues = (step: string) => {
    switch (step) {
      case "first":
        if (newChildren.name === "" || newChildren.className === "") {
          setStepperMessage("Please, fill in all the fields");
          return false;
        } else {
          setStepperMessage("");
          return true;
        }
      case "second":
        console.log(selectedTown);
        if (selectedTown === "" || selectedTown === undefined) {
          setStepperMessage("Please, select a Town");
          console.log("hetre");
          return false;
        } else {
          setStepperMessage("");
          return true;
        }
      default:
        break;
    }
  };

  const handleChildrenStopAttanding = async (childId: string) => {
    navigate("/children/" + childId + "/stop-attending/");
  };

  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  if (!userData.city || !userData.phoneNumber || !userData.postCode || !userData.name || !userData.surname) {
    navigate("/profile");
  }

  const handlePaymentSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPayment(e.target.value);
  };

  return (
    <>
      <div className="user-childrens-container">
        <Typography variant="h5" gutterBottom>
          {childrens.length === 1 ? "Moje dieťa informácie" : "Moje deti informácie"}
        </Typography>

        <TableContainer component={Paper}>
          <Table className="children-table">
            <TableHead>
              <TableRow>
                <TableCell>Meno</TableCell>
                <TableCell>Škola</TableCell>
                <TableCell>Krúžok</TableCell>
                <TableCell>Jazyk</TableCell>
                <TableCell>Trieda</TableCell>
                <TableCell>Navštevuje</TableCell>
                {/* <TableCell>First Payment</TableCell> */}
                <TableCell>Druh platby</TableCell>
                <TableCell>Ďalšia platba</TableCell>
                <TableCell>Vytvorené</TableCell>
                <TableCell>Dochádzka</TableCell>
                <TableCell>Detaily</TableCell>
                <TableCell>Odhlásiť z krúžku</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {childrens.map((child) => (
                <TableRow key={child._id}>
                  <TableCell>{child.name}</TableCell>
                  <TableCell>{child.schoolName}</TableCell>
                  <TableCell>{child.courseName}</TableCell>
                  <TableCell>{child.courseLanguage}</TableCell>
                  <TableCell>{child.className}</TableCell>
                  <TableCell>{child.isAttending ? "Ano" : "Nie"}</TableCell>
                  <TableCell>{child.paymentMethod === "once" ? "Jednorázovo" : "Mesačne"}</TableCell>
                  <TableCell>
                    {child.nextPayment === 999 ? <Box>Jednorazová platba</Box> : setNumbertoMonth(child.nextPayment)}
                  </TableCell>
                  <TableCell>{new Date(child.createdAt).toLocaleDateString()}</TableCell>
                  <TableCell className="mui-action-cell">
                    <Button
                      variant="contained"
                      className="details-button-attendance"
                      onClick={() => handleChildrenAttendanceDetails(child._id)}
                    >
                      Detaily
                    </Button>
                  </TableCell>
                  <TableCell className="mui-action-cell">
                    <Button
                      variant="contained"
                      className="details-button-attendance2"
                      onClick={() => handleChildrenDetails(child._id)}
                    >
                      Detaily
                    </Button>
                  </TableCell>
                  <TableCell className="mui-action-cell">
                    {child.isAttending ? (
                      <Button
                        variant="contained"
                        className="details-button-attendance3"
                        onClick={() => handleChildrenStopAttanding(child._id)}
                      >
                        Odhlásiť
                      </Button>
                    ) : (
                      <Box className="details-button-attendance5">-</Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* Stepper */}
      {!hidden ? (
        <>
          <div className="add-child-container">
            <h2>Registrácia dieťaťa na kurz</h2>
          </div>
          <Stepper activeStep={activeStep} alternativeLabel connector={<StepConnector className="hideConnector" />}>
            {stepNames.map((stepName, index) => (
              <Step key={index}>
                <StepLabel>{stepName}</StepLabel>
                <br />
                <StepContent>
                  {index === 0 ? (
                    <div>
                      <div className="input-container">
                        <label className="form-label">
                          Meno a priezvisko žiaka
                          {/* <Tooltip title="Enter the child's full name here">
                        <InfoIcon className="info-icon" />
                      </Tooltip> */}
                        </label>
                        <div className="input-field-container">
                          <input
                            type="text"
                            value={newChildren.name}
                            onChange={(e) => setNewChildren({ ...newChildren, name: e.target.value })}
                            placeholder="Meno a priezvisko žiaka"
                            className="input-field"
                          />
                        </div>
                      </div>
                      <div>
                        <label className="form-label">
                          Trieda žiaka
                          <Tooltip title="Zadajte celé meno/priezvisko dieťaťa a jeho názov triedy">
                            <InfoIcon className="info-icon" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          value={newChildren.className}
                          onChange={(e) => setNewChildren({ ...newChildren, className: e.target.value })}
                          placeholder="Trieda žiaka"
                          className="input-field"
                        />
                        <button
                          className="first-button"
                          onClick={() => {
                            const res = checkStepperValues("first");
                            if (res) {
                              setActiveStep(index + 1);
                            }
                          }}
                        >
                          Ďalej
                        </button>
                        {stepperMessage ? <p className="warning-message">{stepperMessage}</p> : null}
                      </div>
                    </div>
                  ) : null}
                  {index === 1 ? (
                    <div className="form-label">
                      <div className="form-label">
                        <label>
                          Zvoľte mesto:
                          <select value={selectedTown} onChange={handleSelectTown}>
                            <option value="">-- Zvoľte mesto --</option>
                            {towns &&
                              towns.map((town) => (
                                <option key={town._id} value={town.name}>
                                  {town.name}
                                </option>
                              ))}
                          </select>
                        </label>
                        <label>
                          Zvoľte školu:
                          <select value={selectedSchoolId} onChange={handleSchoolSelect}>
                            <option value="">-- Zvoľte školu: --</option>
                            {schools &&
                              schools.map((school) => (
                                <option key={school._id} value={school._id}>
                                  {school.name}
                                </option>
                              ))}
                          </select>
                        </label>
                      </div>
                      <div className="stepper-button-container">
                        <button className="stepper-button-back" onClick={() => setActiveStep(index - 1)}>
                          Späť
                        </button>
                        <button
                          className="stepper-button"
                          onClick={() => {
                            const res = checkStepperValues("senond");
                            console.log(res);
                            // if (res) {
                            //   setActiveStep(index + 1);
                            // }
                            setActiveStep(index + 1);
                            // TODO
                          }}
                        >
                          Ďalej
                        </button>
                        {stepperMessage ? <p className="warning-message">{stepperMessage}</p> : null}
                      </div>
                    </div>
                  ) : null}

                  {index === 2 ? (
                    <div className="form-label">
                      <div className="form-label2">
                        <label>
                          Vyberte programovací jazyk:
                          <select value={selectedLanguage} onChange={handleLanguageSelect}>
                            <option value="">-- Vyberte programovací jazyk --</option>
                            {languages &&
                              languages.map((language) => (
                                <option key={language._id} value={language.name}>
                                  {language.name}
                                </option>
                              ))}
                          </select>
                        </label>
                        <p></p>
                        <label>
                          Vyberte kurz:
                          <select value={selectedCourseId} onChange={handleCourseSelect}>
                            <option value="">-- Vyberte kurz --</option>
                            {courses &&
                              courses.map((course) => (
                                <option key={course._id} value={course._id}>
                                  {course.time}
                                </option>
                              ))}
                          </select>
                        </label>
                        {freeCoursePlaces > 0 ? <p>Voľné miesta: {freeCoursePlaces}</p> : <p>Nie sú voľné miesta</p>}
                      </div>
                      <div className="stepper-button-container5">
                        <button className="stepper-button-back" onClick={() => setActiveStep(index - 1)}>
                          Späť
                        </button>
                        <button className="stepper-button" onClick={() => setActiveStep(index + 1)}>
                          Ďalej
                        </button>
                      </div>
                    </div>
                  ) : null}

                  {/* {index === 3 ? (
                <div className="form-label">
                  <div className="form-label2">
                    <label>
                      Vyberte kurz:
                      <select value={selectedCourseId} onChange={handleCourseSelect}>
                        <option value="">-- Vyberte kurz --</option>
                        {courses &&
                          courses.map((course) => (
                            <option key={course._id} value={course._id}>
                              {course.time}
                            </option>
                          ))}
                      </select>
                    </label>
                  {freeCoursePlaces ? (
                      <p>Voľné miesta: {freeCoursePlaces}</p>
                  ) : null}
              
                  </div>
                  <div className="stepper-button-container2">
                    <button className="stepper-button-back" onClick={() => setActiveStep(index - 1)}>
                      Späť
                    </button>
                    <button
                      className="stepper-button"
                      onClick={() => {
                        setActiveStep(index + 1);
                      }}
                    >
                      Ďalej
                    </button>
                  </div>
                </div>
              ) : null} */}
                  {index === 4 ? (
                    <div className="form-label4">
                      <div className="form-label3">
                        <label>
                          Všeobecné obchodné podmienky:
                          <GeneralTermsAndConditions
                            userId={userId}
                            token={token ? token : ""}
                            userData={userData}
                            isChecked={isChecked}
                            onCheckboxChange={setIsChecked}
                            userSignedPersonalData={userSignedPersonalData}
                            updateUserData={updateUserData}
                          />
                        </label>
                        <label>
                          Súhlas so spracovaním osobných údajov:
                          <PersonalDataAgreement
                            isChecked={isVOPChecked}
                            onCheckboxChange={setIsVOPChecked}
                            userSignedPersonalData={userSignedVOP}
                            updateUserData={updateUserData}
                            userId={userId}
                            token={token ? token : ""}
                            userData={userData}
                          />
                        </label>
                      </div>
                      <div className="button-container">
                        <button className="stepper-button-back" onClick={() => setActiveStep(index - 1)}>
                          Späť
                        </button>
                      </div>
                    </div>
                  ) : null}
                  {index === 3 ? (
                    <div className="form-label">
                      <div className="form-label2">
                        <label>
                          Zvoľte spôsob platby:
                          <select value={selectedPayment} onChange={handlePaymentSelect}>
                            <option value="">-- Zvoľte spôsob platby --</option>
                            <option value="once">Jednorazová platba</option>
                            <option value="monthly">Mesačná platba</option>
                          </select>
                          <p>
                            Cena pri jednorázovej platbe: {selectedCourse.monthPayment - 0.5} €/hod
                            <Tooltip title="Jednorázová platba poskytuje možnosť získať 0.5 eurocen zľavu na hodinovú sadzbu">
                              <InfoIcon className="info-icon" />
                            </Tooltip>
                          </p>
                          <p>Cena pri mesačnej platbe: {selectedCourse.monthPayment} €/hod</p>
                        </label>
                      </div>
                      <div className="stepper-button-container2">
                        <button className="stepper-button-back" onClick={() => setActiveStep(index - 1)}>
                          Späť
                        </button>
                        <button
                          className="stepper-button"
                          onClick={() => {
                            setCompleteStepper(true);
                            setActiveStep(index + 1);
                          }}
                        >
                          Ďalej
                        </button>
                      </div>
                    </div>
                  ) : null}
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {/* End of stepper */}
          <div className="button-container">
            {completeStepper && userData.signedVOP && userData.signedPersonalData && selectedPayment ? (
              <>
                <button className="create-button" onClick={createChildren}>
                  Záväzne prihlásiť dieťa na kurz
                </button>
              </>
            ) : null}
          </div>
        </>
      ) : null}
      <br />
      <br />
    </>
  );
};

export default UserChildrens;
