import {
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  InputLabel,
  FormControl,
  Grid,
} from "@mui/material";
import { SiJavascript, SiPython, SiScratch, SiTypescript, SiRoblox, SiHtml5 } from "react-icons/si";
import { IconType } from "react-icons";
import { useEffect, useState } from "react";
import { fetchSchools } from "../../utils/fetchSchools";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "@material-ui/core";

export enum DifficultyLevel {
  EASY = "Jednoduchá",
  MEDIUM = "Stredá",
  HARD = "Ťažká",
}

interface School {
  _id: string; // Use _id as the unique identifier
  name: string;
  location: string;
  town: string;
  address: string; // Add address property
  imgURL: string; // Add imgURL property
  // Add other properties as needed
}

interface Courses {
  _id: string;
  time: string;
  language: string;
  startDate: string;
  endDate: string;
  lectorId: string;
  schoolName: string;
  lectorName: string;
  monthPayment: number;
  capacity: number;
  schoolId: string;
  childrens: string[];
  attendIds: string[];
  //
}

export enum Language {
  PYTHON = "Python",
  JAVASCRIPT = "JavaScript",
  SCRATCH = "Scratch",
  TYPESCRIPT = "TypeScript",
  ROBLOX = "Roblox",
  HTML = "HTML",
}

export interface CourseCardProps {
  language: Language;
  title: string;
  description: string;
  difficultyLevel: DifficultyLevel;
  courseDuration: string;
  capacity: number;
  suitableFor: string;
  price: string;
  isDarkTheme: boolean;
  conditions?: string;
  gradeLevel: string;
}

const getLanguageIcon = (language: Language): IconType => {
  switch (language) {
    case Language.PYTHON:
      return SiPython;
    case Language.JAVASCRIPT:
      return SiJavascript;
    case Language.SCRATCH:
      return SiScratch;
    case Language.TYPESCRIPT:
      return SiTypescript;
    case Language.ROBLOX:
      return SiRoblox;
    case Language.HTML:
      return SiHtml5;
    default:
      throw new Error(`Unsupported language`);
  }
};
const iconStyle = {
  backgroundColor: "#FFD966", // Orange color
  padding: "10px",
  borderRadius: "50%",
};

const CourseCard = ({
  language,
  title,
  description,
  difficultyLevel,
  courseDuration,
  capacity,
  suitableFor,
  price,
  isDarkTheme,
  conditions,
  gradeLevel,
}: CourseCardProps) => {
  const LanguageIcon = getLanguageIcon(language);

  const cardBackgroundColor = isDarkTheme ? "#222" : "#fafafa";
  const textColor = isDarkTheme ? "white" : "black";

  // Add states for town and school
  const [town, setTown] = useState("");
  const [school, setSchool] = useState("");
  const [isDetailsClicked, setIsDetailsClicked] = useState(false);

  // Add arrays for towns and schools (you can replace these with real data)
  const towns = ["Bratislava", "Nitra", "Kosice", "Hlohovec", "Piestany"];
  const [schools, setSchools] = useState<School[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [selectedSchoolName, setSelectedSchoolName] = useState<string>("");
  const [selectedCourses, setSelectedCourses] = useState<Courses[]>([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);

  const handleIconClick = () => {
    if (!clicked) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
    setClicked(!clicked);
  };

  const getCourseDetails = (language: string, title: string, difficultyLevel: DifficultyLevel) => {
    setIsDetailsClicked(true);
    setSelectedLanguage(language);
  };

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    if (isDetailsClicked === true && town !== "") {
      fetchSchools(town, false)
        .then((data) => {
          setSchools(data);
        })
        .catch((error) => console.log(error));
    }
  }, [isDetailsClicked, school, town, url]);

  // useEffect to fetch course by town and school
  useEffect(() => {
    async function fetchCourseByTownAndSchool() {
      if (selectedLanguage !== "" && town !== "" && school !== "") {
        try {
          const response = await fetch(
            url + "course/getCoursesBySchoolIdAndLanguage/" + school + "/" + selectedLanguage + "/public",
            {
              headers: {
                "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
              },
            }
          );
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          const data = await response.json();
          setSelectedCourses(data.coursesInSchool);
          // TODO - set childrens to the data from the response
        } catch (error) {
          console.error("Error fetching children data:", error);
        }
      }
    }
    fetchCourseByTownAndSchool();
  }, [school, town, selectedLanguage, url]);

  const detailsOfCourse = (courseId: string) => {
    navigate("/course/details-user/" + courseId);
  };

  const handleInterest = (courseId: string) => {
    // selectedSchoolName is schoold id so get school name from schools array
    const schoolName = schools.find((school) => school._id === selectedSchoolName)?.name || "";
    const language = selectedLanguage; // Replace with your variable or value

    const urlWithParams = `/course-interest?schoolName=${encodeURIComponent(schoolName)}&language=${encodeURIComponent(
      language
    )}`;
    navigate(urlWithParams);
  };

  return (
    <div
      style={{
        display: "inline-block",
        justifyContent: "space-between",
        width: "100%",
        margin: 0,
        marginBottom: 20,
      }}
    >
      <Card
        sx={{
          width: "100%",
          textAlign: "center",
          backgroundColor: cardBackgroundColor,
        }}
      >
        <CardHeader
          avatar={
            <div style={iconStyle}>
              <LanguageIcon size={30} />
            </div>
          }
          title={
            <Typography variant="h6" component="h6" color={textColor}>
              <strong>{title}</strong>
            </Typography>
          }
        />
        <CardContent>
          <div style={{ textAlign: "center", padding: 2 }}>
            <Typography variant="body2" color={textColor} component="h1" style={{ fontSize: 14 }}>
              {description}
            </Typography>
          </div>
          <br />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              alignItems: "center",
              textAlign: "left",
              padding: 2,
            }}
          >
            <div>
              <Typography
                variant="subtitle2"
                sx={{ marginTop: "auto", fontWeight: "bold" }}
                style={{ fontSize: 12, color: textColor }}
              >
                Náročnosť: {difficultyLevel}
              </Typography>
              <Typography
                variant="body2"
                sx={{ marginTop: "auto", fontWeight: "bold" }}
                color={textColor}
                component="p"
                style={{ fontSize: 12 }}
              >
                Trvanie: {courseDuration}
              </Typography>
              <Typography
                variant="body2"
                color={textColor}
                component="p"
                sx={{ marginTop: "auto", fontWeight: "bold" }}
                style={{ fontSize: 12 }}
              >
                Kapacita: {capacity}
              </Typography>
              <Typography
                variant="body2"
                color={textColor}
                component="p"
                sx={{ marginTop: "auto", fontWeight: "bold" }}
                style={{ fontSize: 12 }}
              >
                Vhodné pre: {gradeLevel === "first" ? "1. stupeň" : "2. stupeň"} - ({suitableFor})
              </Typography>
              {conditions ? (
                <Typography
                  variant="body2"
                  color={textColor}
                  component="p"
                  sx={{ marginTop: "auto", fontWeight: "bold" }}
                  style={{ fontSize: 12 }}
                >
                  Podmienky: {conditions}
                </Typography>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                padding: 2,
              }}
            >
              <Typography
                variant="h6"
                color={textColor}
                component="p"
                sx={{ marginTop: "auto", fontWeight: "bold" }}
                style={{ fontSize: 16, display: "flex", alignItems: "center" }}
              >
                Cena: {price}
                <span
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  onClick={handleIconClick}
                  onTouchStart={() => setShowTooltip(true)}
                  onTouchEnd={() => setShowTooltip(false)}
                >
                  {showTooltip && (
                    <Tooltip title="Cena sa odvíja od mesta, poplatku za prenájom a obsadenosti" open>
                      <InfoIcon className="info-icon" style={{ marginLeft: "8px", alignSelf: "center" }} />
                    </Tooltip>
                  )}
                  {!showTooltip && (
                    <InfoIcon className="info-icon" style={{ marginLeft: "8px", alignSelf: "center" }} />
                  )}
                </span>
              </Typography>
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 4 }}>
                {!isDetailsClicked ? (
                  <button
                    style={{
                      backgroundColor: isDarkTheme ? "#ffffff" : "#0077c2",
                      color: isDarkTheme ? "#222" : "#ffffff",
                      borderRadius: "4px",
                      border: "none",
                      padding: "4px 8px",
                      cursor: "pointer",
                      fontSize: 12,
                    }}
                    onClick={() => getCourseDetails(language, title, difficultyLevel)}
                  >
                    Dostupnosť kurzov
                  </button>
                ) : (
                  <button
                    style={{
                      backgroundColor: isDarkTheme ? "#ffffff" : "#0077c2",
                      color: isDarkTheme ? "#222" : "#ffffff",
                      borderRadius: "4px",
                      border: "none",
                      padding: "4px 8px",
                      cursor: "pointer",
                      fontSize: 12,
                    }}
                    onClick={() => {
                      setIsDetailsClicked(false);
                      setTown("");
                      setSchool("");
                      setSelectedCourses([]);
                    }}
                  >
                    Skryť dostupnosť
                  </button>
                )}
                {isDetailsClicked ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="town-label">Mesto</InputLabel>
                          <Select
                            labelId="town-label"
                            value={town}
                            onChange={(e) => setTown(e.target.value)}
                            style={{ minWidth: 120 }} // Set a fixed width
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 48 * 4.5, // Control the height of the dropdown
                                  width: 250, // Control the width of the dropdown
                                },
                              },
                            }}
                            inputProps={{
                              style: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }, // Truncate long text with ellipsis
                            }}
                          >
                            {towns.map((town) => (
                              <MenuItem value={town}>{town}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="school-label">Škola</InputLabel>
                          <Select
                            labelId="school-label"
                            value={school}
                            onChange={(e) => {
                              setSelectedSchoolName(e.target.value);
                              setSchool(e.target.value);
                            }}
                            style={{ minWidth: 120 }} // Set a fixed width
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 48 * 4.5, // Control the height of the dropdown
                                  width: 250, // Control the width of the dropdown
                                },
                              },
                            }}
                            inputProps={{
                              style: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }, // Truncate long text with ellipsis
                            }}
                          >
                            {schools.map((school) => {
                              return <MenuItem value={school._id}>{school.name}</MenuItem>;
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          {selectedCourses.length === 0 && isDetailsClicked && town !== "" && school !== "" ? (
            <div style={{ textAlign: "center", padding: 2 }}>
              <br />
              {/* <Typography variant="body2" component="h1" style={{ fontSize: 14 }}>
                Na tejto škole ešte nie sú priradené konkrétne dni a časy pre zvolený. Prosím, vyplnte{" "}
                <Link href="#/course-interest">formulár</Link> pre predbežný záujem a my Vás budeme včas kontaktovať
              </Typography> */}
              <Typography variant="body2" component="h1" style={{ fontSize: 14 }}>
                Na tejto škole ešte nie sú priradené konkrétne dni a časy pre zvolený krúžok. Prosím, vyplnte{" "}
                <Link
                  component="button" // makes the link render as a button element
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                    textDecoration: "underline",
                    padding: 0,
                  }}
                  onClick={() => handleInterest("")}
                >
                  formulár
                </Link>{" "}
                pre predbežný záujem a my Vás budeme včas kontaktovať!
              </Typography>
              <button style={{ backgroundColor: "blue" }} onClick={() => handleInterest("")}>
                Mám záujem v prípade pridania konkrétnych časov tohto krúžku
              </button>
              <br />
            </div>
          ) : null}
          {selectedCourses.length > 0 && isDetailsClicked ? (
            <>
              <div>
                <p>Dostupné kurzy:</p>
                {selectedCourses.map((course) => {
                  // if course startDate is 2023 than dont show it
                  if (course.startDate.split("-")[0] === "2023") {
                    return null;
                  }
                  let dostupnost =
                    course.capacity - course.childrens.length > 0 ? "Dostupný" : "Nedostupný (0 voľných miest)";
                  // let pocetDostupnych = course.capacity - course.childrens.length;
                  let textColor = dostupnost === "Dostupný" ? "green" : "red";
                  if (course.childrens.length === 0) {
                    dostupnost = "Dostupný";
                    // pocetDostupnych = course.capacity;
                    textColor = "green";
                  }
                  return (
                    <>
                      <Typography
                        variant="body2"
                        component="p"
                        sx={{ marginTop: "auto", fontWeight: "bold", color: textColor }}
                        style={{ fontSize: 12 }}
                      >
                        {course.time} - <span style={{ color: textColor }}> {dostupnost} - </span>{" "}
                        {dostupnost === "Dostupný"}
                        {dostupnost === "Dostupný" ? (
                          <>
                            <button
                              style={{
                                backgroundColor: isDarkTheme ? "#ffffff" : "#0077c2",
                                color: isDarkTheme ? "#222" : "#ffffff",
                                borderRadius: "4px",
                                border: "none",
                                marginLeft: 10,
                                padding: "4px 8px",
                                cursor: "pointer",
                                fontSize: 12,
                              }}
                              onClick={() => {
                                detailsOfCourse(course._id);
                              }}
                            >
                              Detaily kurzu
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              style={{
                                backgroundColor: "blue",
                                borderRadius: "4px",
                                border: "none",
                                padding: "4px 8px",
                                cursor: "pointer",
                                fontSize: 12,
                              }}
                              onClick={() => handleInterest(course._id)}
                            >
                              Mám záujem v prípade voľného miesta
                            </button>
                          </>
                        )}
                      </Typography>
                    </>
                  );
                })}
              </div>
              <p className="login-note">Pre úspešnú prihlášku dieťaťa do krúžku je potrebné prihlásenie!</p>
            </>
          ) : null}
        </CardContent>
      </Card>
    </div>
  );
};

export default CourseCard;
